<template>
  <router-link
    tag="div"
    :to="
      !isActive ? {
        path: '/home/booking/reservation-details' + '/' + this.id + '/',
        params: { id }
      } : ''
    "
    class="p-relative rounded pa-4"
    :class="{ 'bg-w-silver cursor-pointer': !isActive, 'bg-white px-4 my-5': bgWhite }"
  >
    <v-container>
      <v-row>
        <div v-if="showId">
          予約ID：{{ id }}
        </div>
      </v-row>
      <v-row>
        <v-col class="d-flex pt-2 pb-2" cols="12" :sm="isSoon ? 6 : 0">
          <v-img
            :src="getImage(urlImg)"
            width="73"
            height="73"
            max-width="73"
            :lazy-src="lazyImgUrl"
            class="mr-3"
            :class="{ 'circle ml-0': isActive }"
          />

          <div class="item-data-container">
            <div v-if="cancelledAt" class="--red cancelled">({{ $t("common.cancelled") }})</div>
            <div
              class="text-medium --w-medium line--1"
              :class="{ '--prm mb-3': !isActive, '--medium-gray': isActive }"
            >
              {{ title }}
            </div>
            <div
              class="text-regular"
              :class="{ '--prm font-weight-bold': isActive }"
            >
              {{ checkInDate | jaLongDate }} ~ {{ checkOutDate | jaLongDate }}
            </div>
            <div
              class="text-regular d-flex"
              :class="{ '--dark': isActive }"
            >
              {{ roomTypeName }}
            </div>
            <div
              v-if="representativeName"
              class="text-regular --prm d-flex"
            >
              {{$t('reservationAndUsageHistory.accommodationRepresentative')}}: {{ representativeName }}
            </div>
          </div>
        </v-col>

        <v-col v-if="isSoon" cols="0" :sm="isSoon ? 6 : 0">
          <div class="--dark-red bg-white text-center rounded-10 pa-8 ">
            <span class="--red">
              {{ $t("homePage.availableServiceNotice.line1") }}
            </span>
            <br />
            {{ $t("homePage.availableServiceNotice.line2") }}
          </div>
          <div></div>
          <v-btn
            elevation="0"
            rounded
            block
            :to="'/home/booking/reservation-details/' + this.id"
            outlined
            x-large
            class="bg-white --prm-light mt-3 mb-3"
          >
            {{ $t("reservationAndUsageHistory.enterInformationAndConfirmUsage") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </router-link>
</template>

<script>
import { lazyImageMixin } from '@/mixins/lazy-image.mixin'

export default {
  name: 'UsageHistoryItem',
  mixins: [lazyImageMixin],
  props: {
    id: [Number, String],
    title: String,
    urlImg: String,
    isSoon: {
      type: Boolean,
      default: false
    },
    checkInDate: String,
    checkOutDate: String,
    roomTypeName: String,
    representativeName: String,
    isActive: Boolean,
    showId: Boolean,
    bgWhite: {
      type: Boolean,
      default: false
    },
    cancelledAt: {
      type: [Date, String],
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
.circle {
  border-radius: 100px
}
.item-data-container {
  position: relative;
}
.cancelled {
  position: absolute;
  top: -1.5em;
}
</style>
